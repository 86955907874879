import PlayFab from 'playfab-sdk';

// console.log('Playfab init')
// 0xCORE
PlayFab.settings.titleId = '88C3C';
PlayFab.settings.developerSecretKey = '4GXSJM71KYCATJG5KXC8JEFKSM49IFXHE1HBDINZDY7MQWPC97'; // default

const loginRequest = {
    // Currently, you need to look up the correct format for this object in the API-docs:
    // https://api.playfab.com/Documentation/Client/method/LoginWithCustomID
    TitleId: PlayFab.settings.titleId,
    CustomId: "AUTOMETA-Frontend-dev",
    CreateAccount: true
}; 

// eslint-disable-next-line
const playFabCallback = (error, result) => {
    // eslint-disable-next-line
    if (result) {
        // console.log(result)
    // eslint-disable-next-line
    } else if (error) {
        // console.log(error)
    }
}

PlayFab.PlayFabClient.LoginWithCustomID(loginRequest, playFabCallback)