import router from '@/router'
import { isNil } from 'lodash'
import { createNewUserFromFirebaseAuthUser } from '@/misc/helpers'
import UsersDB from '@/firebase/users-db'
// import firebase from 'firebase/app'
// import firestore from '@/firebase/async-firestore'
import { getCookie, deleteCookie } from '@/js/cookies'

export default {
	/**
	 * Callback fired when user login
	 */
	// login: async ({ commit, dispatch }, firebaseAuthUser) => {
	login: async ({ commit }, firebaseAuthUser) => {
		// console.log('🏬 store authentication.actions.js login')
		// console.log('🏬 store authentication.actions.js isAnonymous', firebaseAuthUser.isAnonymous)
		if (firebaseAuthUser.isAnonymous) return

		// console.log(firebaseAuthUser.uid)

		// Firestore
		const userFromFirebase = await new UsersDB().read(firebaseAuthUser.uid)
		// console.log('🏬 store authentication.actions.js login', userFromFirebase)

		// RTDB
		// let userFromFirebase;
		// await firebase.database().ref(`users/${firebaseAuthUser.uid}`).once().then((snapshot) => {
		//   userFromFirebase = snapshot.val()
		//   console.log('🏬 store authentication.actions.js login',userFromFirebase)
		// }).catch((error) => {
		//   console.error(error);
		// });

		const user = isNil(userFromFirebase)
			? await createNewUserFromFirebaseAuthUser(firebaseAuthUser)
			: userFromFirebase

		const token = getCookie('_user_token')
    
		commit('setUser', user)
		commit('setSessionToken', token)
		// dispatch('products/getUserProducts', null, { root: true })
	},

	/**
	 * Callback fired when user logout
	 */
	logout: ({ commit }) => {
		// console.log('🏬 store authentication.actions.js logout / Anonymous')
		deleteCookie('_user_token')
		commit('setUser', null)
		commit('setSessionToken', null)
		const currentRouter = router.app.$route
		if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
			router.push('/login')
		}
	},
	/**
	 * user register
	 * authed anonymous?
	 * firebase auth?
	 */
	// register: async (user) => {
		// const idToken = await firebase.auth().currentUser.getIdToken(true)

		// const userFromFirebase = await new UsersDB().read(user.uid)
		// console.log('🏬 store authentication.actions.js register', userFromFirebase)

		// if (isNil(userFromFirebase)) await createNewUserFromFirebaseAuthUser(user)

		// TODO: default photo from firebase storage
		// const photoUpdate = (await firestore()).collection('users').doc(user.uid)
			
		// photoUpdate
		// 	.set({
		// 		photoURL: ''
		// 	}, {merge: true})

		// const userInfo = {
		// 	displayName: user.displayName,
		// 	email: user.email,
		// 	avatar: user.photoURL,
		// 	userID: user.uid
		// }
		// console.log(userInfo)

		// save user data to store if auto signIn is needed
		// await commit('setUser', userInfo)
	// }
}
