// import UserProductsDB from '@/firebase/user-products-db'
import firebase from 'firebase/app'
// import { cloneDeep } from 'lodash';
// import { time } from 'vue-analytics'

const timetableSetting = [
	{
		day: 'day1',
		stage: 'Cinema'
	},
	{
		day: 'day1',
		stage: 'DJ'
	},
	{
		day: 'day1',
		stage: 'Ocean'
	},
	{
		day: 'day1',
		stage: 'Street'
	},
	{
		day: 'day2',
		stage: 'Cinema'
	},
	{
		day: 'day2',
		stage: 'DJ'
	},
	{
		day: 'day2',
		stage: 'Ocean'
	},
	{
		day: 'day2',
		stage: 'Street'
	},
	{
		day: 'day3',
		stage: 'Cinema'
	},
	{
		day: 'day3',
		stage: 'DJ'
	},
	{
		day: 'day3',
		stage: 'Ocean'
	},
	{
		day: 'day3',
		stage: 'Street'
	},
	{
		day: 'day4',
		stage: 'Cinema'
	},
	{
		day: 'day4',
		stage: 'DJ'
	},
	{
		day: 'day4',
		stage: 'Ocean'
	},
	{
		day: 'day4',
		stage: 'Street'
	}
]
// const db = firebase.firestore()

const deepCopyFunction = (inputObject) => {
	// Return the value if inputObject is not an Object data
	// Need to notice typeof null is 'object'
	if (typeof inputObject !== 'object' || inputObject === null) {
	  return inputObject;
	}
  
	// Create an array or object to hold the values
	const outputObject = Array.isArray(inputObject) ? [] : {};
  
	Object.keys(inputObject).forEach(key => {
		const value = inputObject[key];
		outputObject[key] = deepCopyFunction(value);
	})
  
	return outputObject;
  }
  

export default {
	/**
	 * Fetch timetable
	 */
	getTimetable: async ({ commit }) => {
		let day = []
		let stage = []
		const db = firebase.firestore()
		const projectPath = '_projects/23-01-SunsetTown/showData/video_and_timetable/timetable'
		const timetable = await timetableSetting.reduce((paths, e) => {
			day.push(e.day)
			stage.push(e.stage)
			const timetableRef = db.collection(
				`${projectPath}/${e.day}/${e.stage}`
			)
			timetableRef
				.get()
				.then(snapshot => {
					snapshot.forEach(doc => {
						const info = doc.data()
						info.day = e.day
						info.stage = e.stage
						info.time = doc.id.slice(2)

						paths.push(info)
					})
				})
				.catch(err => {
					console.log('Error getting documents', err)
				})
			return paths
		}, [])
		day = [...new Set(day)]
		stage = [...new Set(stage)]
		setTimeout(() => {
			// console.log(cloneTable)
			// todo: have to look at a callback when fetch completed, timetable's data is still fetching, shallow copy will casue state pointing to the same memony w/ ${timetable} and keep it changing outside the mutation phrase
			const cloneTable = deepCopyFunction(timetable)
			commit('setTimetable', cloneTable)
			commit('setDay', day)
			commit('setStage', stage)
		}, 10000)
	},
	/**
	 * Fetch timetable
	 */
	getVideo: async ({ commit }) => {
		const video = []
		const db = firebase.firestore()
		db.collectionGroup('video')
			.get()
			.then(doc => {
				if (doc) {
					doc.forEach(data => {
						video.push(data.data())
					})
					commit('setVideo', video)
				} else {
					console.log('that document does not exist')
				}
			})
			.catch(error => {
				console.error('Error getting documents: ', error)
			})
	},
	getStageInfo: async ({ commit }) => {
		// console.log('🔥 getStageInfo ')
		// const stageinfo
		const db = firebase.firestore()
		db.collection('_projects/23-01-SunsetTown/frontend')
			.doc('stageData')
			.get()
			.then(doc => {
				if (doc) {
					// stageinfo.push(doc.data())
					const stageinfo = doc.data()
					commit('setStageInfo', stageinfo)
					commit('OnGotStageInfo', true)
				} else {
					console.log('that document does not exist')
				}
			})
			.catch(error => {
				console.error('Error getting documents: ', error)
			})
	}
	// /**
	//  * Fetch products of current loggedin user
	//  */
	// getUserProducts: async ({ rootState, commit }) => {
	// 	console.log('🔥 getUserProduct ')
	// 	const userProductDb = new UserProductsDB(rootState.authentication.user.id)

	// 	const products = await userProductDb.readAll()
	// 	commit('setProducts', products)
	// },

	// /**
	//  * Create a product for current loggedin user
	//  */
	// createUserProduct: async ({ commit, rootState }, product) => {
	// 	const userProductDb = new UserProductsDB(rootState.authentication.user.id)

	// 	commit('setProductCreationPending', true)
	// 	const createdProduct = await userProductDb.create(product)
	// 	commit('addProduct', createdProduct)
	// 	commit('setProductCreationPending', false)
	// },

	// /**
	//  * Create a new product for current loggedin user and reset product name input
	//  */
	// triggerAddProductAction: ({ dispatch, state, commit }) => {
	// 	if (state.productNameToCreate === '') return

	// 	const product = { name: state.productNameToCreate }
	// 	commit('setProductNameToCreate', '')
	// 	dispatch('createUserProduct', product)
	// },

	// /**
	//  * Delete a user product from its id
	//  */
	// deleteUserProduct: async ({ rootState, commit, getters }, productId) => {
	// 	if (getters.isProductDeletionPending(productId)) return

	// 	const userProductsDb = new UserProductsDB(rootState.authentication.user.id)

	// 	commit('addProductDeletionPending', productId)
	// 	await userProductsDb.delete(productId)
	// 	commit('removeProductById', productId)
	// 	commit('removeProductDeletionPending', productId)
	// }
}
