import Vue from 'vue'
import VueGtag from 'vue-gtag'
import rtdbPlugin from 'vuefire'
import browserDetect from "vue-browser-detect-plugin"
import Vue2TouchEvents from 'vue2-touch-events'
// import LoadScript from 'vue-plugin-load-script'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/misc/register-service-worker'
import '@/misc/handle-network-status'
import '@/firebase/init'
import '@/playfab/init'
import '@/firebase/authentication'
import '@/misc/handle-apple-install-prompt'
import 'pwacompat'



Vue.use(
  VueGtag,
  {
    config: {
      // id: 'G-WQB2QJ6N13' // GTAG for ID0
      id: 'G-9E2RNPK28Z' // Gtag for SunsetTown
    },
  },
)


// Vue.use(
//     rtdbPlugin
// )
Vue.use(browserDetect);
// Vue.use(LoadScript);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  rtdbPlugin,
  Vue2TouchEvents,
  render: h => h(App)
}).$mount('#app')

// eslint-disable-next-line
// const vm = new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

// global.vm = vm

// // eslint-disable-next-line
// function UnityMessengerText(text) {
//   console.log(text)
// }

// // Gloable Event Test
// vm.$on('onUnityMessage', msg => {
//   console.log(msg)
// })
