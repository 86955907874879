export default {
	// products: null,
	// productNameToCreate: '',
	// productDeletionPending: [],
	// productCreationPending: false,
	timetable: [],
	video: [],
	stage: [],
	day: [],
	stageinfo: null,
	connected: false,
	location: '',
	gotStageInfo: false,
	gotVideo: false,
	gotTimetable: false
}
