import firebase from 'firebase/app'
// import { isNil } from 'lodash'

import store from '@/store'
import { uuidv4 } from '@/js/uuidv4'
import { setCookie } from '@/js/cookies'
// import { firebaseApp } from './init'

// console.log('🔥 authentication.js')

const db = firebase.firestore()
let sessionToken = ''
let tokenSnapshot = null

// eslint-disable-next-line
const sessionTokenListener = (firebaseUser) => {
  const userRef = db.collection('users').doc(firebaseUser.uid)
  tokenSnapshot = userRef.onSnapshot(async snapShot => {
    // if snapshot token is not exist, add one
    if (!snapShot.exists) {
      console.log('sessionToken is not exists')
      store.commit('app/setMessageFadeTime', 5000)
      store.commit('app/setMessageToastrContent', 'Your account is not exist, please try login again or contact to administrator.')
      firebase.auth().signInAnonymously()
      tokenSnapshot()
      return;
    }
    // console.log('authentication', sessionToken, snapShot.data().sessionToken)
    
    // TOCHECK: sometimes logout accidentally
    if (sessionToken !== snapShot.data().sessionToken) {
      console.log('sessionToken is not match')
      console.log(sessionToken)
      console.log(snapShot.data().sessionToken)
      store.commit('app/setMessageFadeTime', 5000)
      store.commit('app/setMessageToastrContent', 'Your account is signedIn on another device, or login expired, please signIn again.')
      firebase.auth().signInAnonymously()
      tokenSnapshot() // cancel listen snapshot
    }
    // console.log('ST_Listener Init')
  })
}

// eslint-disable-next-line
const updateSessionToken = (firebaseUser) => {
  const userRef = db.collection('users').doc(firebaseUser.uid)
  userRef.set({
    sessionToken
  }, { merge: true })
  .then(() => {
    // console.log('updateSessionToken success')

    // tofixs: estimate the network stable, some network may update slowly
    // or start listening on game started
    setTimeout(() => {
      sessionTokenListener(firebaseUser)
    }, 5000)
  }).catch(e => {
    console.log(e.message)
  })
}

const storeGameToken = (firebaseUser) => {
  const gameToken = uuidv4('hash')
  // console.log(gameToken)
  const userRef = db.collection('users').doc(firebaseUser.uid)
  userRef.set({
    gameToken
  }, { merge: true }).then(() => {
    store.commit('authentication/setGameToken', gameToken)
    // this.setGameToken(gameToken)
  })
}


firebase.auth().onAuthStateChanged(async firebaseUser => {
  // if (!firebaseUser.isAnonymous && getCookie('_user_token') === null) {
  if (!firebaseUser) {
    await firebase.auth().signInAnonymously().then( userCredential => {
      const {user} = userCredential
      console.log('👤 authentication onAuthStateChanged', user.uid)
    })
    sessionToken = ''
    return;
  } 
  if (!firebaseUser.isAnonymous) {
    // console.log('!isAnonymous')
    sessionToken = uuidv4('hash')
    // console.log(sessionToken)
    // const sessionToken = uuidv4('hash')
    setCookie('_user_token', sessionToken, 7)
    updateSessionToken(firebaseUser)
    storeGameToken(firebaseUser)
    store.dispatch(`authentication/login`, firebaseUser)
  } else {
    // console.log('isAnonymous')
    store.dispatch(`authentication/logout`, null)
  }

  // default
  // console.log('🔥 authentication.js onAuthStateChanged', firebaseUser)
  // const actionToDispatch = isNil(firebaseUser) ? 'logout' : 'login'
  // store.dispatch(`authentication/${actionToDispatch}`, firebaseUser)
})