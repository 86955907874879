import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/functions'

// NAXS-PF-DEV firebase
// const config = {
//   apiKey: 'AIzaSyBkuUd-8PozIYgVoZOWs-1x-O7nQFCooww',
//   authDomain: 'naxs-pf-dev.firebaseapp.com',
//   databaseURL: 'https://naxs-pf-dev.firebaseio.com',
//   projectId: 'naxs-pf-dev',
//   storageBucket: 'naxs-pf-dev.appspot.com',
//   messagingSenderId: '1063572406999',
//   appId: '1:1063572406999:web:1441eff7b7ad7fa35ae692',
//   measurementId: 'G-3QSWLLE8NV'
// }

// SRVC-SunsetMusic
const config = {
  apiKey: "AIzaSyAwgJk2UfbLzIKVWt-NHUt6C26hmEjR_cA",
  // authDomain: "srvc-sunsetmusic.firebaseapp.com",
  authDomain: "play.sunset.town",
  databaseURL: "https://srvc-sunsetmusic-default-rtdb.firebaseio.com",
  projectId: "srvc-sunsetmusic",
  storageBucket: "srvc-sunsetmusic.appspot.com",
  messagingSenderId: "212005933530",
  appId: "1:212005933530:web:139aa5485e5dd868e9b0e3",
  measurementId: "G-9E2RNPK28Z"
};

// CLB-BodyCrysis
// const config = {
//   apiKey: "AIzaSyB8-BB6n4-i8YYYRcQCQx0UlK5AmncsVnM",
//   authDomain: "clb-bodycrysis.firebaseapp.com",
//   databaseURL: "https://clb-bodycrysis-default-rtdb.firebaseio.com",
//   projectId: "clb-bodycrysis",
//   storageBucket: "clb-bodycrysis.appspot.com",
//   messagingSenderId: "250124587029",
//   appId: "1:250124587029:web:064454330cff5ee7c77e1c",
//   measurementId: "G-P25H5E2VHC"
// };

// console.log('🔥 firebase init.js')
export const firebaseApp = firebase.initializeApp(config)
// export const db = firebaseApp.firestore()
export const rtdb = firebaseApp.database()
export const storage = firebaseApp.storage()
export const functions = firebase.functions()




