export default {
  // setUser: (state, value) => (state.user = value)
  setUser(state, value) {
    // console.log('🏬 mutation setUser')
    state.user = value
  },
  setSessionToken(state, value) {
    state.sessionToken = value
  },
  setGameToken(state, value) {
    state.gameToken = value
  }
}
