import { uuid } from 'vue-uuid'

/* eslint-disable import/prefer-default-export */
export const uuidv4 = (mode) => {
    if (mode === 'hash') {
        const hash = uuid.v4().replace(/-/g, '')
        // console.log(hash)
        return hash
    }
    const hash = uuid.v4()
    return hash
}