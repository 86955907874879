import Vue from 'vue'
import Router from 'vue-router'
// import Head from 'vue-head'
// eslint-disable-next-line
import CheckLogin from '@/views/CheckLogin'
import { isNil } from 'lodash'
import store from '@/store'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

// Vue.use(Head, {
// 	complement: process.env.VUE_APP_TITLE
// })

// Vue.use(Head, {
// 	complement: ''
// })

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		// {
		// 	path: '/home',
		// 	name: 'home',
		// 	component: () =>
		// 		import(
		// 			/* webpackChunkName: "client-chunk-product-details" */ '@/views/SRVC_SunsetTown_home.vue'
		// 		),
		// 	meta: {
		// 		authNotRequired: true
		// 	}
		// },
		{
			path: '/dev',
			name: 'dev',
			props: { 
				isDemo: true,
				isDev: true
			},
			component: () =>
				import(
					/* webpackChunkName: "client-chunk-product-details" */ '@/views/SRVC_SunsetTown_main.vue'
				),
			meta: {
				authNotRequired: true,
				title: 'Sunset Town Festival 夕陽小鎮虛擬音樂節 | Virtual Game - Dev'
			}
		},
		// {
		// 	path: '/register',
		// 	name: 'register',
		// 	component: () =>
		// 		import(
		// 			/* webpackChunkName: "client-chunk-product-details" */ '@/views/SRVC_SunsetTown_register.vue'
		// 		),
		// 	meta: {
		// 		authNotRequired: true
		// 	}
		// },
		{
			path: '/',
			name: 'play',
			component: () =>
				import(
					/* webpackChunkName: "client-chunk-product-details" */ '@/views/SRVC_SunsetTown_main.vue'
				),
			meta: {
				authNotRequired: true,
				title: 'Sunset Town Festival 夕陽小鎮虛擬音樂節 | Virtual Game'
			}
		},
		// {
		// 	path: '/demo/:linkid',
		// 	name: 'furioosPlayer',
		// 	props: true,
		// 	meta: {
		// 		authNotRequired: true
		// 	},
		// 	component: () =>
		// 		import(
		// 			/* webpackChunkName: "client-chunk-product-details" */ '@/views/Proj_DAF.vue'
		// 		)
		// },
		// {
		// 	path: '/check-login',
		// 	name: 'check-login',
		// 	component: CheckLogin,
		// 	meta: {
		// 		authNotRequired: true
		// 	}
		// },
		// {
		// 	path: '/dev',
		// 	name: 'dev',
		// 	props: true,
		// 	meta: {
		// 		authNotRequired: true
		// 	},
		// 	component: () =>
		// 		import(
		// 			'@/views/Dev.vue'
		// 		)
		// },
		// {
		// 	path: '/dev-scream',
		// 	name: 'dev-scream',
		// 	props: true,
		// 	meta: {
		// 		authNotRequired: true
		// 	},
		// 	component: () =>
		// 		import(
		// 			'@/views/Dev_Scream2.vue'
		// 		)
		// },
		{ path: '*', redirect: '/' }
	]
})

/**
 * Handle user redirections
 */
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
	if (
		!(to.meta && to.meta.authNotRequired) &&
		isNil(store.state.authentication.user)
	) {
		const path =
			store.state.authentication.user === null ? '/login' : '/check-login'
		return next(`${path}?redirectUrl=${to.path}`)
	}
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

export default router
