export default {
  setNetworkOnline: (state, value) => (state.networkOnLine = value),
  setSWRegistrationForNewContent: (state, value) =>
    (state.SWRegistrationForNewContent = value),
  setShowAddToHomeScreenModalForApple: (state, value) =>
    (state.showAddToHomeScreenModalForApple = value),
  setRefreshingApp: (state, value) => (state.refreshingApp = value),
  setPreloading: (state, value) => (state.preloading = value),
  setMessageToastrContent: (state, value) => (state.message = value),
  setMessageFadeTime: (state, value) => (state.msgfadeTimeIntervals = value)
}
