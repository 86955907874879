<template>
	<div id="app">
		<!--<nav-bar :Navimg="Navimg" @logout="deleteImg"></nav-bar>-->
		<div class="main-wrapper">
			<router-view @onUnitySystemLoaded="onUnitySystemLoaded"></router-view>
		</div>

		<new-content-available-toastr
			v-if="newContentAvailable"
			class="new-content-available-toastr"
			:refreshing-app="refreshingApp"
			@refresh="serviceWorkerSkipWaiting"
		></new-content-available-toastr>
	</div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { isMobile } from 'mobile-device-detect'
import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
import 'firebase/firestore'

export default {
	components: {
		NewContentAvailableToastr,
	},
	watch: {
	},
	head() {
		return {
			title: {
				inner: 'Sunset Town Festival 夕陽小鎮虛擬音樂節 : Game',
			},
			meta: [
				{
					name: 'description',
					content: '夕陽音樂 x NAXS FUTURE 攜手打造虛擬小鎮 ! 玩家可創建角色購票登入夕陽小鎮 參與一年一度音樂盛事',
					id: 'desc',
				},
				{
					name: 'viewport',
					content: 'width=device-width, initial-scale=1, viewport-fit=cover, maximum-scale=1, user-scalable=0',
				},
				{
					name: 'apple-mobile-web-app-capable',
					content: 'yes'
				},
				{
					name: 'mobile-web-app-capable',
					content: 'yes'
				},
				{ property: 'og:title', content: 'Sunset Town' },
				{
					property: 'og:image',
					content: 'img/OGImage.png',
				},
			],
		}
	},
	computed: {
		...mapGetters('app', ['newContentAvailable']),
		...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp']),
		isMobile() {
			return isMobile
		}
	},
	data() {
		return {
			isUnitySystemLoaded: false,
			entered: false,
		}
	},

	methods: {
		...mapActions('app', [
			'closeAddToHomeScreenModalForApple',
			'serviceWorkerSkipWaiting',
		]),
		onUnitySystemLoaded() {
			this.isUnitySystemLoaded = true
		},
	},
}
</script>

<style lang="scss">
@import '@/theme/transitions.scss';
@import '@/theme/layout.scss';
@import '@/theme/form.scss';
@import '@/theme/modal.scss';
@import '@/theme/Autometa.scss';
@import '@/theme/user.scss';
@import '@/theme/preloading.scss';
// @import '@/theme/reset.scss';

#brim-mask {
	touch-action: auto;
	* {
		touch-action: auto;
	}
}

html,
body {
	width: 100vw;
	overflow-x: hidden;
	margin: 0;
	left: 0;
}

body {
	background: #000;
	// background: darkslategrey;

	a {
		// font-weight: 500;
		text-decoration: none;
		color: black;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	label,
	button {
		// color: #ccc;
		color: white;
	}

	#app {
		font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
			Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: 16px;
		color: #2c3e50;
		min-height: 100vh;
		margin: 0;
		display: block;
		justify-content: center;
		align-items: center;

		.new-content-available-toastr {
			position: absolute;
			bottom: 6vw;
			right: 1vw;
			z-index: 1000;
		}

		.apple-add-to-home-screen-modal {
			position: absolute;
			bottom: 0;
			right: 0;
			top: 0;
			left: 0;
			height: fit-content;
			width: fit-content;
			margin: auto;
			z-index: 1000;
		}

		.main-wrapper {
			.page-wrapper {
				width: 60%;
				min-height: 100%;
				margin: auto;
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;

				@media screen and (max-width: 1000px) {
					width: 100%;
				}
			}
		}
	}
}
</style>
