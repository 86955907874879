// Ref: https://xon5.medium.com/a-vue-modal-manager-via-vuex-1ae530c8649
const store = {
	namespaced: true,
	state: {
		open: [],
		userMode: '' // only for ModelUser: login, register
	},
	getters: {
		active: (state) => (state.open.length > 0 ? state.open[0] : null),
		allOpen: (state) => state.open,
	},
	mutations: {
		OPEN: (state, payload) => {
			// console.log('modals mutation open', payload)
			state.open.unshift(payload.name)
			state.userMode = payload.userMode
			// console.log(state.open)
		},
		CLOSE: (state, payload) => (state.open = state.open.filter((e) => e !== payload)),
		CLOSEALL: (state) => (state.open = []),
	},
	actions: {
		open: ({ commit }, payload) => {
			// console.log('modals actions open', payload)
			commit('OPEN', payload)
		},
		close: ({ commit }, payload) => commit('CLOSE', payload),
		closeAll: ({ commit }, payload) => commit('CLOSEALL', payload),
	},
}

export default store
