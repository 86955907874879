export default {
	/* 從 Firebase 取得資料後共用 */
	setTimetable: (state, value) => (state.timetable = value),
	setVideo: (state, value) => (state.video = value),
	setDay: (state, value) => (state.day = value),
	setStage: (state, value) => (state.stage = value),
	setStageInfo: (state, value) => (state.stageinfo = value),

	/* 紀錄已取用過資料 */
	setConnected: (state, value) => (state.connected = value),
	/* 紀錄要傳給 Unity 的資料 */
	setLocation: (state, value) => (state.location = value),

	OnGotStageInfo: (state, value) => (state.gotStageInfo = value),
	OnGotVideo: (state, value) => (state.gotVideo = value),
	OnGotTimetable: (state, value) => (state.gotTimetable = value),

	// /* Product input name */
	// setProductNameToCreate: (state, productNameToCreate) =>
	// 	(state.productNameToCreate = productNameToCreate),

	// /* Products */
	// setProducts: (state, products) => (state.products = products),
	// addProduct: (state, product) => state.products.push(product),
	// removeProductById: (state, productId) => {
	// 	const index = state.products.findIndex(product => product.id === productId)
	// 	state.products.splice(index, 1)
	// },

	// /* Products deletion */
	// addProductDeletionPending: (state, productId) =>
	// 	state.productDeletionPending.push(productId),
	// removeProductDeletionPending: (state, productId) => {
	// 	const index = state.products.findIndex(product => product.id === productId)
	// 	state.productDeletionPending.splice(index, 1)
	// },

	// /* Product creation */
	// setProductCreationPending: (state, value) =>
	// 	(state.productCreationPending = value)
}
